
import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';
import usePage from '@/composables/usePage';

export default defineComponent({
  head: {},
  setup() {
    const isLoading = ref(false);
    const isLoaded = ref(false);
    const { page, fetchPage } = usePage('main');

    useFetch(async () => {
      isLoading.value = true;
      await fetchPage();
      isLoaded.value = true;

      if (process.client) {
        setTimeout(() => {
          isLoading.value = false;
        }, 50);
      } else {
        isLoading.value = false;
      }
    });

    return {
      page,
      isLoading,
      isLoaded,
    };
  },
});
